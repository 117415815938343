<template>
  <div class="container">
    <h2 class="library-new-books__title">
      {{ $t('$app.library_news') }}
    </h2>
    <VRow v-if="filteredNewLibrary.length">
      <VCol
        v-for="item in filteredNewLibrary"
        :key="item.id"
        xl="4"
        sm="6"
        xs="12"
      >
        <RouterLink
          style="text-decoration: none; color: inherit;"
          :to="`/library/book/${item.id}`"
        >
          <div class="library-new-books bvi-speech">
            <VCard class="library-new-books__card">
              <div class="library-new-books__img-group">
                <img
                  v-if="item.cover"
                  class="library-new-books__img"
                  :src="`/${ item.cover }`"
                  alt="img"
                >
                <img
                  v-else
                  class="library-new-books__img-default"
                  src="/assets/images/default-document-cover.svg"
                  alt="img"
                >
              </div>
              <div class="library-new-books__items">
                <VCardTitle
                  v-show="item.title"
                  :title="item.title"
                  class="library-new-books__card-title"
                >
                  {{ promotedLibraryTitle (item) }}
                </VCardTitle>
                <ul class="library-new-books__tags">
                  <li
                    v-for="tag in promotedLibraryTags (item)"
                    :key="tag.id"
                    :title="tag"
                    class="library-new-books__tag"
                  >
                    {{ tag }}
                  </li>
                </ul>
                <VCardText
                  v-show="item.author"
                  class="library-new-books__card-text-author"
                >
                  <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ promotedLibraryAuthor (item) }}
                </VCardText>
                <VCardText
                  v-show="item.publication_year"
                  class="library-new-books__card-text-year"
                >
                  <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ item.publication_year }}
                </VCardText>
              </div>
            </VCard>
          </div>
        </RouterLink>
      </VCol>
    </VRow>
    <UPlaceholder v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/getters/types'
import UPlaceholder from '@components/UPlaceholder.vue'

export default {
  name: 'LibraryNewBooks',
  components: {
    UPlaceholder
  },

  props: {
    search: {
      type: String,
      default: '',
    }
  },

  computed: {
    ...mapGetters({
      library: getters.LIBRARY
    }),

    promotedLibrary () {
      return this.library?.filter((item) => {
        return item.is_promoted
      })
    },

    filteredNewLibrary () {
      if (this.search) {
        const search = this.search.toUpperCase()
        const compareValues = (value) => {
          return value.toUpperCase().includes(search)
        }

        return this.promotedLibrary.filter((document) => {
          const titleMatch = compareValues(document.title)
          const authorMatch = compareValues(document.author)
          const yearMatch = compareValues(document.publication_year)
          const tagsMatch = (document.tags || []).some(compareValues)
          return titleMatch || authorMatch || tagsMatch || yearMatch
        })
      }
      return this.promotedLibrary
    }
  },

  methods: {
    promotedLibraryTitle (item) {
      if (item.title.length > 60) {
        return item.title.slice(0, 60) + '...'
      } else {
        return item.title
      }
    },

    promotedLibraryAuthor (item) {
      if (item.author.length > 20) {
        return item.author.slice(0, 20) + '...'
      } else {
        return item.author
      }
    },

    promotedLibraryTags (item) {
      return (item.tags || []).slice(0, 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.library-new-books {
  height: 100%;
}

.library-new-books__title {
  margin-bottom: 1rem;
}

.library-new-books__tags {
  padding: 10px;
}

.library-new-books__tag {
  display: inline-block;
  max-width: 6em;
  font-size: 0.75em;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.5ch 1ch;
  padding: 0.5ch 1ch;
  border-radius: 0.75em;
  background-color: var(--v-background-base);
  border: solid 1px var(--v-text-base);
  color: var(--v-text-base);
}

.library-new-books__img-group {
  display: contents;
}

.library-new-books__img,
.library-new-books__img-default {
  width: 50%;
  padding: 1rem;
  object-fit: contain;
}

.library-new-books__items {
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: column;
}

.library-new-books__card {
  display: flex;
  height: 100%;
  min-height: 312px;
  transition: linear 0.1s;
}

.library-new-books__card:hover {
  opacity: 0.8;
}

.library-new-books__card-title {
  display: block;
}
</style>
