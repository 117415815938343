<template>
  <div class="container">
    <h2 class="library-all-books__title">
      {{ $t('$app.library_all_materials') + ':' }}
    </h2>
    <VRow v-if="extendedSearchLibrary">
      <template v-for="search in extendedSearchLibrary">
        <VCol
          :key="search.id"
          xl="4"
          sm="6"
          xs="12"
        >
          <RouterLink
            style="text-decoration: none; color: inherit;"
            :to="`/library/book/${search.id}`"
          >
            <div class="library-all-books bvi-speech">
              <VCard class="library-all-books__card">
                <div class="library-all-books__img-group">
                  <img
                    v-if="search.cover"
                    class="library-all-books__img"
                    :src="`/${ search.cover }`"
                    alt="img"
                  >
                  <img
                    v-else
                    class="library-all-books__img-default"
                    src="/assets/images/default-document-cover.svg"
                    alt="img"
                  >
                </div>
                <div class="library-all-books__items">
                  <VCardTitle
                    v-show="search.title"
                    :title="search.title"
                    class="library-all-books__card-title"
                  >
                    {{ shortenedLibraryTitle (search) }}
                  </VCardTitle>
                  <ul class="library-all-books__tags">
                    <li
                      v-for="tag in shortenedLibraryTags (search)"
                      :key="tag.id"
                      :title="tag"
                      class="library-all-books__tag"
                    >
                      {{ tag }}
                    </li>
                  </ul>
                  <VCardText v-show="search.author">
                    <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ shortenedLibraryAuthor (search) }}
                  </VCardText>
                  <VCardText v-show="search.publication_year">
                    <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ search.publication_year }}
                  </VCardText>
                </div>
              </VCard>
            </div>
          </RouterLink>
        </VCol>
      </template>
    </VRow>
    <UPlaceholder v-else />
  </div>
</template>

<script>
import UPlaceholder from '@components/UPlaceholder.vue'

export default {
  name: 'LibraryallBooks',
  components: {
    UPlaceholder
  },

  props: {
    extendedSearchLibrary: {
      type: Array,
      default: () => []
    },
  },

  methods: {
    shortenedLibraryTitle (search) {
      if (search.title.length > 60) {
        return search.title.slice(0, 60) + '...'
      } else {
        return search.title
      }
    },

    shortenedLibraryAuthor (search) {
      if (search.author.length > 20) {
        return search.author.slice(0, 20) + '...'
      } else {
        return search.author
      }
    },

    shortenedLibraryTags (search) {
      return (search.tags || []).slice(0, 3)
    }
  }
}
</script>

<style lang="scss">
.library-all-books .bvi-speech-text {
  height: 100%;
}

.library-all-books  {
  height: 100%;
}

.library-all-books__title {
  margin-bottom: 1rem;
}

.library-all-books__tags {
  padding: 10px;
}

.library-all-books__tag {
  display: inline-block;
  max-width: 6em;
  font-size: 0.75em;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.5ch 1ch;
  padding: 0.5ch 1ch;
  border-radius: 0.75em;
  background-color: var(--v-background-base);
  border: solid 1px var(--v-text-base);
  color: var(--v-text-base);
}

.library-all-books__img-group {
  display: contents;
}

.library-all-books__img,
.library-all-books__img-default {
  width: 50%;
  padding: 1rem;
  object-fit: contain;
}

.library-all-books__items {
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: column;
}

.library-all-books__card {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 312px;
  transition: linear 0.1s;
}

.library-all-books__card:hover {
  opacity: 0.8;
}

.library-all-books__card-title {
  display: block;
}
</style>
