<template>
  <div class="container">
    <h2>{{ $t('$app.library_catalog') }}</h2>
    <VTreeview
      v-if="treeViewItems.length > 0"
      class="tree-view__items"
      hoverable
      activatable
      :items="treeViewItems"
      @update:active="linkToItems"
    />
    <div
      v-else
      class="tree-view__items-none"
    >
      Нет разделов
    </div>
    <RouterLink
      :to="'/library/documents'"
      @click.native="$router.go(0)"
    >
      <VBtn
        class="tree-view__button"
        color="primary"
      >
        {{ $t('$app.library_all_materials') }}
      </VBtn>
    </RouterLink>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as actions from '@/store/actions/types'

export default {
  name: 'LibraryTreeView',

  computed: {
    treeViewItems () {
      const a = (list, parentId) => {
        return list.filter(item => item.parent_id === parentId).map(item => {
          return {
            id: item.id,
            name: item.title + ' ' + `${item.nodes_count > 0 ? `(${item.nodes_count})` : ''}`,
            children: a(list, item.id),
          }
        })
      }
      return a(this.$store.getters.LIBRARY_CATALOGS ?? [], 0)
    }
  },

  created () {
    this.getLibraryCatalogs()
  },

  methods: {
    ...mapActions({
      getLibraryCatalogs: actions.GET_LIBRARY_CATALOGS
    }),

    linkToItems (value) {
      this.$router.push(`/library/documents/${value}`)
    },
  }

}
</script>

<style lang="scss">
.tree-view__button {
  margin: 20px 0 25px 0;
}

.tree-view__items-none {
  color: #919191;
}

.tree-view__items:hover {
  cursor: pointer;
}

.v-treeview-node__label {
  white-space: break-spaces;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .tree-view__button {
    margin: 20px 0 0 0;
  }
}
</style>
