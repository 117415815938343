<template>
  <UBanner>
    <article class="box">
      <h1 class="page-title">
        {{ librarySettings.title }}
      </h1>
      <VCard class="library__card">
        <VBreadcrumbs
          :items="breadCrumbs"
          divider=">"
        />
        <VCol
          cols="12"
          lg="12"
        >
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            :placeholder="$t('$app.library_searching_by_author_title_year_keywords') + ':'"
            clearable
            hide-details
          />
        </VCol>
        <VContainer class="library__buttons-container">
          <VBtn
            id="allBooks"
            :outlined="libraryTreeViewShow"
            color="primary"
            class="library-catalog__button"
            @click="libraryTreeViewShow = !libraryTreeViewShow"
          >
            <VIcon> {{ libraryTreeViewShow ? 'arrow_drop_down' : 'arrow_drop_up' }} </VIcon>
            {{ $t('$app.library_catalog') }}
          </VBtn>
        </VContainer>
        <VRow class="library__row-container">
          <VCol
            :class="{ 'library__tree-view': libraryTreeViewShow }"
            lg="3"
            xs="12"
          >
            <LibraryTreeView />
          </VCol>
          <VCol
            lg="9"
            xs="12"
          >
            <LibraryNewBooks :search="search" />
          </VCol>
        </VRow>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UBanner from '@components/UBanner.vue'
import documentTitle from '@/mixins/documentTitle'
import LibraryTreeView from '@components/LibraryTreeView.vue'
import LibraryNewBooks from '@components/LibraryNewBooks.vue'

export default {
  name: 'TheLibrary',

  components: {
    LibraryNewBooks,
    LibraryTreeView,
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      libraryTreeViewShow: true,
      documentTitle: '$sdo.lc_menu_library.one',
      search: '',
    }
  },

  computed: {
    ...mapGetters({
      librarySettings: getters.LIBRARY_SETTINGS,
    }),

    breadCrumbs () {
      return [
        {
          text: this.$store.getters.LIBRARY_SETTINGS.title,
          disabled: true,
        },
      ]
    }
  },

  created () {
    this.getLibrary()
    this.getLibrarySettings()
  },

  updated () {
    this.pageScroll()
  },

  methods: {
    ...mapActions({
      getLibrary: actions.GET_LIBRARY,
      getLibrarySettings: actions.GET_LIBRARY_SETTINGS,
    }),

    pageScroll () {
      const scrollElement = document.querySelector('.library-catalog__button')
      if (this.$vuetify.breakpoint.smAndDown) {
        scrollElement.scrollIntoView()
      } else {
        window.scrollTo(0, 0)
      }
    },
  }

}
</script>

<style lang="scss">
.library-new-books .bvi-speech-text {
  height: 100%;
}

.library-new-books {
  height: 100%;
}

.library__row-container .col {
  flex-basis: auto;
}

.v-breadcrumbs {
  li {
    max-width: 30%;
    word-break: break-word;
  }
}

.page-title {
  color: #919191;
}

.page-title:hover {
  text-decoration: none;
  cursor: pointer;
}

.library__buttons-container {
  margin-left: 0;
}

.v-application {
  ul {
    padding: 18px 12px;
  }
}

.library-catalog__button {
  display: none;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }

  .library-catalog__button {
    display: inline;
    margin-right: 1rem;
  }

  .library__tree-view {
    display: none;
  }

  .library__row-container .container {
    max-width: inherit;
  }
}
</style>
